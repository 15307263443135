import { environment } from '@env';
import { EndPoint } from '@core/constants';
import { UrlResolver } from '@core/utils';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export function userManagerSettingsFactory(): UserManagerSettings {
    return {
        authority: UrlResolver.getUrl(EndPoint.identity),
        client_id: 'frontend',
        redirect_uri: environment.host + '/sign-in/callback',
        post_logout_redirect_uri: environment.host,
        response_type: 'code',
        scope: 'openid profile cpapi cpresource',
        filterProtocolClaims: true,
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        silent_redirect_uri: environment.host + '/silent-renew.html',
        includeIdTokenInSilentRenew: true
    };
}
