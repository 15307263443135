import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertService, GlobalsService, SettingsDataService } from '@core/services';
import { CopyUtils } from '@core/utils';
import { take } from 'rxjs';

@Component({
    templateUrl: './twofa-required-dialog.component.html',
    styleUrls: ['./twofa-required-dialog.component.scss']
})
export class TwofaRequiredDialogComponent {
    qrLoading = true;
    errorLoadingQr = false;
    loading = false;

    pageIndex = 0;

    twoFactorKey?: string;
    verificationCode?: string;
    recoveryCodes: string[] = [];
    qrCode?: SafeResourceUrl;

    constructor(
        private dialogRef: MatDialogRef<TwofaRequiredDialogComponent, boolean>,
        private api: SettingsDataService,
        private alert: AlertService,
        private sanitizer: DomSanitizer,
        private globals: GlobalsService,
    ) { }

    get formattedKey(): string {
        if (!this.twoFactorKey) {
            return '';
        }

        return [...this.twoFactorKey].map((char, index) => index % 4 === 0 ? ' ' + char : char).join('').trim();
    }

    onClickEnable(): void {
        this.loading = true;

        this.api.enableTwoFa().pipe(take(1)).subscribe({
            next: (response) => {
                this.twoFactorKey = response.key;
                this.pageIndex = 1;
                this._loadQR();
                this.loading = false;
            }, error: () => {
                this.alert.postMessage('Something went wrong enabling two-factor authentication for your account, please try again later!', 'Error', 5000);
                this.loading = false;
            }
        })
    }

    submitCode(): void {
        if (!this.verificationCode) {
            return;
        }

        this.loading = true;

        this.api.verifyTwoFa(this.verificationCode).pipe(take(1)).subscribe({
            next: (response) => {
                this.recoveryCodes = response.recoveryCodes;
                this.globals.twoFactorChanged(true);
                this.pageIndex = 2;
                this.loading = false;
            }, error: () => {
                this.alert.postMessage('Something went wrong verifying your two-factor authentication code, please try again later!', 'Error', 5000);
                this.loading = false;
            }
        });
    }

    copyKey(): void {
        if (this.twoFactorKey) {
            CopyUtils.CopyToClipboard(this.twoFactorKey);
        }
    }

    onClickDone(): void {
        this.dialogRef.close(true);
    }

    private _loadQR(): void {
        if (!this.twoFactorKey) {
            return;
        }

        this.qrLoading = true;
        this.errorLoadingQr = false;

        this.api.getTwoFaQR(this.twoFactorKey).pipe(take(1)).subscribe({
            next: (response) => {
                if (response && response.body) {
                    this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response.body));
                }
                this.qrLoading = false;
            }, error: () => {
                this.errorLoadingQr = true;
                this.qrLoading = false;
            }
        });
    }
}
