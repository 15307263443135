import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, AlertService } from '@core/services';
// A lot of anys because result could be anything
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, from, lastValueFrom, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult } from '@shared/components';

@Injectable()
export class SessionHttpInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthenticationService, private alert: AlertService,
        private router: Router, private dialog: MatDialog) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => from(this.handle(req, error, next))));
    }

    async handle(req: HttpRequest<any>, error: HttpErrorResponse, next: HttpHandler): Promise<HttpEvent<any>> {
        // If the error was from an unauthorized result check if this caused by an invalid session
        if (error.status === 401 && this.auth.hasSession && req.url.indexOf('identity') === -1) {
            // If the session is active the user simply tried to reach a restricted path so propagate the error.
            if (this.auth.hasActiveSession) {
                return lastValueFrom(throwError(() => error));
            }

            // If there is a session but it is inactive we want to try and refresh it.
            this.alert.postMessage('Your session has expired, we are trying to reestablish it.', 'Light', 3000);

            try {
                const user = await this.auth.attemptSilentSignIn();

                if (user) {
                    this.alert.postMessage('The session was reestablished', 'Light', 3000);
                    const newRequest = req.clone({
                        headers: this.auth.accessToken
                            ? req.headers.set('authorization', [`Bearer ${this.auth.accessToken}`])
                            : req.headers
                    });
                    return lastValueFrom(next.handle(newRequest));
                } else {
                    this.handleFailedSignin();
                    return lastValueFrom(EMPTY);
                }
            } catch (_) {
                this.handleFailedSignin();
                return lastValueFrom(EMPTY);
            }
        } else {
            return lastValueFrom(throwError(() => error));
        }
    }

    handleFailedSignin(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: new ConfirmDialogData(
                'We could not renew your session in the background. To continue the session has to be renewed,' +
                 'but to do so we need to refresh the page. You can choose to do this now, or to do so yourself later,' +
                 'optionally through opening a new browser tab.',
                'primary',
                undefined,
                false,
                'Reset session'
            ),
            panelClass: 'small-dialog'
        });

        dialogRef.afterClosed().pipe(take(1))
            .subscribe(
                (result: ConfirmDialogResult) => {
                    if (result === 'continue') {
                        this.auth.startAuthentication(this.router?.routerState?.snapshot?.url);
                    }
                }
            );
    }
}
