import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private snackbar: MatSnackBar) { }

    public postMessage(message: string, type: AlertType, duration?: number, action?: string): MatSnackBarRef<unknown> {
        return this.snackbar.open(message, action, {
            duration,
            panelClass: this.determinePanelClass(type)
        });
    }

    private determinePanelClass(type: AlertType): string {
        switch (type) {
        case 'Light':
            return 'light-snackbar';
        case 'Dark':
            return 'dark-snackbar';
        case 'Error':
            return 'error-snackbar';
        }
    }
}

export type AlertType = 'Light' | 'Dark' | 'Error';
