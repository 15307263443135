<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 2441 486" style="enable-background:new 0 0 2441 486;" xml:space="preserve">
    <style type="text/css">
        .st0 {
            fill: #FFFFFF;
        }

        .st1 {
            fill: #FFFFFF;
        }
    </style>
    <path class="st0"
        d="M329.94,320.07c-1.21-1.22-2.8-1.84-4.39-1.84c-1.59,0-3.18,0.61-4.39,1.84
	C300.73,340.66,273.57,352,244.68,352c-59.64,0-108.15-48.9-108.15-109c0-60.1,48.52-109,108.15-109
	c28.89,0,56.05,11.34,76.48,31.93c1.21,1.22,2.8,1.84,4.39,1.83c1.59,0,3.18-0.61,4.4-1.83l85.23-85.89c2.43-2.45,2.43-6.41,0-8.86
	C369.63,25.28,309.09,0,244.68,0C111.73,0,3.57,109.01,3.57,243s108.16,243,241.11,243c64.4,0,124.95-25.28,170.49-71.17
	c2.43-2.45,2.43-6.41,0-8.86L329.94,320.07z M353.87,124.1c-29.8-27.85-68.32-43.14-109.19-43.14C156.02,80.96,83.9,153.65,83.9,243
	s72.13,162.04,160.78,162.04c38.81,0,76.28-14.12,105.52-39.77c1.24-1.09,2.47-2.21,3.69-3.34l19.61,19.76
	c-35.06,33.11-80.58,51.34-128.82,51.34c-103.96,0-188.55-85.24-188.55-190.02S140.72,52.98,244.68,52.98
	c48.24,0,93.76,18.22,128.82,51.34L353.87,124.1z M124.1,243c0,67.01,54.09,121.53,120.58,121.53c30.13,0,58.57-11.07,80.76-31.28
	l19.65,19.81c-1.01,0.94-2.03,1.87-3.05,2.76c-26.97,23.66-61.55,36.7-97.36,36.7c-81.8,0-148.35-67.07-148.35-149.51
	c0-82.44,66.55-149.52,148.35-149.52c37.55,0,72.95,13.98,100.4,39.47l-19.64,19.8c-22.19-20.22-50.63-31.28-80.76-31.28
	C178.19,121.47,124.1,175.99,124.1,243z M244.68,473.47C118.58,473.47,16,370.08,16,243C16,115.92,118.58,12.53,244.68,12.53
	c59.01,0,114.6,22.37,157.25,63.13l-19.64,19.8c-37.41-35.48-86.05-55.01-137.61-55.01C133.86,40.45,43.7,131.31,43.7,243
	c0,111.68,90.16,202.55,200.98,202.55c51.55,0,100.2-19.53,137.61-55l19.64,19.79C359.28,451.1,303.69,473.47,244.68,473.47z" />
    <g>
        <path class="st1" d="M709.62,189.21c-2.52-4.07-5.67-7.65-9.44-10.74c-3.78-3.08-8.05-5.47-12.81-7.19
		c-4.76-1.72-9.75-2.59-14.97-2.59c-9.53,0-17.62,1.87-24.26,5.57c-6.65,3.72-12.04,8.69-16.17,14.95
		c-4.14,6.25-7.15,13.36-9.04,21.33c-1.89,7.97-2.83,16.22-2.83,24.73c0,8.15,0.94,16.08,2.83,23.78
		c1.89,7.71,4.89,14.63,9.04,20.79c4.13,6.16,9.53,11.09,16.17,14.81c6.65,3.72,14.74,5.56,24.26,5.56
		c12.95,0,23.06-3.98,30.34-11.95c7.27-7.97,11.72-18.48,13.35-31.52h40.98c-1.08,12.14-3.87,23.1-8.36,32.87
		c-4.5,9.78-10.42,18.12-17.79,25c-7.37,6.89-16,12.14-25.89,15.76s-20.76,5.44-32.63,5.44c-14.74,0-28-2.58-39.77-7.74
		c-11.78-5.16-21.7-12.27-29.79-21.33s-14.29-19.7-18.61-31.93c-4.31-12.23-6.47-25.41-6.47-39.54c0-14.49,2.15-27.94,6.47-40.35
		c4.31-12.41,10.52-23.24,18.61-32.47c8.09-9.24,18.02-16.48,29.79-21.75c11.77-5.25,25.03-7.88,39.77-7.88
		c10.61,0,20.63,1.55,30.07,4.62c9.44,3.08,17.89,7.57,25.35,13.45s13.62,13.18,18.47,21.87s7.91,18.66,9.17,29.89h-40.99
		C713.75,197.78,712.13,193.29,709.62,189.21z" />
        <path class="st1" d="M793.74,234.18c0.54-9.05,2.79-16.57,6.74-22.55c3.95-5.98,8.99-10.78,15.1-14.41
		c6.11-3.62,12.99-6.21,20.62-7.74c7.65-1.54,15.33-2.31,23.06-2.31c7.01,0,14.11,0.49,21.3,1.49c7.18,1,13.75,2.95,19.68,5.84
		c5.94,2.9,10.79,6.93,14.57,12.1c3.77,5.16,5.66,12,5.66,20.51v73.1c0,6.35,0.36,12.42,1.08,18.21c0.72,5.8,1.98,10.15,3.78,13.04
		h-38.83c-0.72-2.18-1.31-4.39-1.75-6.65c-0.45-2.27-0.76-4.58-0.94-6.94c-6.11,6.35-13.3,10.78-21.57,13.32
		c-8.27,2.54-16.72,3.81-25.35,3.81c-6.66,0-12.86-0.81-18.61-2.44c-5.76-1.63-10.79-4.16-15.1-7.61
		c-4.31-3.43-7.68-7.78-10.11-13.04c-2.42-5.25-3.64-11.5-3.64-18.75c0-7.96,1.39-14.53,4.18-19.7c2.79-5.16,6.38-9.28,10.79-12.36
		c4.4-3.08,9.44-5.39,15.1-6.93c5.66-1.54,11.36-2.76,17.12-3.67c5.75-0.91,11.41-1.63,16.99-2.18c5.57-0.54,10.52-1.35,14.83-2.44
		s7.73-2.67,10.24-4.76c2.52-2.08,3.68-5.12,3.51-9.1c0-4.16-0.67-7.48-2.03-9.92c-1.34-2.44-3.14-4.35-5.39-5.71
		c-2.24-1.35-4.85-2.26-7.82-2.71c-2.96-0.46-6.16-0.69-9.57-0.69c-7.55,0-13.48,1.63-17.79,4.9c-4.31,3.26-6.84,8.69-7.56,16.3
		H793.74z M882.18,262.72c-1.62,1.46-3.64,2.58-6.07,3.4c-2.42,0.81-5.04,1.49-7.82,2.04c-2.79,0.54-5.71,1-8.76,1.35
		c-3.06,0.37-6.11,0.81-9.17,1.36c-2.88,0.54-5.7,1.26-8.49,2.17c-2.79,0.92-5.21,2.13-7.28,3.67c-2.06,1.54-3.73,3.49-4.99,5.84
		c-1.26,2.36-1.89,5.35-1.89,8.97c0,3.45,0.63,6.34,1.89,8.69c1.25,2.36,2.97,4.22,5.12,5.57c2.16,1.36,4.68,2.31,7.56,2.86
		c2.87,0.54,5.84,0.81,8.89,0.81c7.56,0,13.39-1.26,17.53-3.81c4.13-2.54,7.19-5.56,9.17-9.1c1.97-3.53,3.19-7.11,3.64-10.74
		c0.45-3.62,0.67-6.53,0.67-8.69V262.72z" />
        <path class="st1" d="M1040.18,190.98v25.82h-28.04v69.57c0,6.53,1.08,10.87,3.23,13.04c2.16,2.18,6.47,3.27,12.95,3.27
		c2.15,0,4.22-0.09,6.2-0.27c1.97-0.18,3.86-0.45,5.66-0.81v29.89c-3.23,0.54-6.83,0.91-10.79,1.09c-3.95,0.17-7.82,0.27-11.59,0.27
		c-5.94,0-11.55-0.4-16.85-1.22c-5.3-0.81-9.98-2.4-14.02-4.76c-4.04-2.35-7.24-5.7-9.57-10.05c-2.34-4.35-3.51-10.06-3.51-17.12
		v-82.88h-23.19v-25.82h23.19v-42.12h38.29v42.12H1040.18z" />
        <path class="st1" d="M1138.59,216.25c-6.11,0-11.23,1.4-15.37,4.21c-4.14,2.81-7.51,6.43-10.12,10.87
		c-2.61,4.44-4.45,9.33-5.53,14.67c-1.07,5.34-1.62,10.64-1.62,15.9c0,5.07,0.54,10.23,1.62,15.49c1.08,5.26,2.83,10.01,5.26,14.27
		c2.43,4.26,5.71,7.74,9.84,10.46c4.13,2.72,9.17,4.08,15.1,4.08c9.16,0,16.22-2.58,21.17-7.75c4.94-5.16,8.04-12.09,9.3-20.78
		h36.94c-2.52,18.66-9.71,32.88-21.57,42.67c-11.87,9.78-27.06,14.67-45.57,14.67c-10.43,0-20.01-1.77-28.72-5.3
		c-8.72-3.53-16.13-8.47-22.24-14.81c-6.11-6.34-10.88-13.9-14.29-22.69c-3.41-8.79-5.12-18.43-5.12-28.95
		c0-10.86,1.57-20.97,4.72-30.29c3.14-9.34,7.77-17.4,13.88-24.19c6.11-6.79,13.57-12.09,22.38-15.9s18.88-5.7,30.2-5.7
		c8.26,0,16.22,1.08,23.86,3.26c7.65,2.17,14.48,5.48,20.5,9.92c6.02,4.44,10.92,9.96,14.69,16.57c3.78,6.62,5.93,14.45,6.47,23.5
		h-37.48C1164.38,224.32,1154.95,216.25,1138.59,216.25z" />
        <path class="st1" d="M1278.53,137.45v73.1h0.81c4.85-8.15,11.05-14.09,18.61-17.8c7.55-3.71,14.92-5.56,22.1-5.56
		c10.25,0,18.65,1.4,25.22,4.21c6.56,2.81,11.72,6.7,15.5,11.69c3.78,4.98,6.42,11.05,7.95,18.2c1.53,7.16,2.29,15.08,2.29,23.78
		v86.41h-38.29v-79.35c0-11.6-1.8-20.24-5.39-25.96c-3.6-5.7-9.97-8.56-19.14-8.56c-10.42,0-17.98,3.13-22.65,9.37
		c-4.68,6.25-7.01,16.54-7.01,30.84v73.65h-38.29V137.45H1278.53z" />
        <path class="st1" d="M1450.02,190.98v17.94h0.54c4.67-7.61,10.61-13.13,17.8-16.57c7.18-3.44,15.09-5.16,23.72-5.16
		c10.97,0,20.41,2.08,28.31,6.24c7.91,4.17,14.47,9.7,19.69,16.58c5.21,6.88,9.07,14.9,11.59,24.04c2.52,9.15,3.78,18.72,3.78,28.67
		c0,9.42-1.26,18.48-3.78,27.17c-2.52,8.69-6.33,16.4-11.45,23.1c-5.13,6.71-11.51,12.05-19.15,16.03s-16.58,5.98-26.83,5.98
		c-8.63,0-16.58-1.77-23.86-5.3c-7.27-3.53-13.26-8.74-17.93-15.62h-0.54v66.57h-38.29V190.98H1450.02z M1499.76,302.39
		c4.22-2.54,7.64-5.84,10.24-9.92c2.61-4.07,4.45-8.83,5.53-14.27c1.07-5.43,1.62-10.96,1.62-16.57c0-5.61-0.59-11.14-1.75-16.57
		c-1.17-5.44-3.11-10.28-5.8-14.54c-2.69-4.25-6.16-7.7-10.38-10.32c-4.22-2.63-9.39-3.94-15.5-3.94c-6.29,0-11.55,1.31-15.77,3.94
		c-4.22,2.63-7.65,6.02-10.24,10.2c-2.61,4.16-4.45,8.96-5.53,14.4c-1.08,5.44-1.62,11.06-1.62,16.85c0,5.62,0.58,11.15,1.75,16.57
		c1.16,5.44,3.05,10.2,5.66,14.27c2.61,4.08,6.08,7.39,10.39,9.92s9.53,3.81,15.64,3.81
		C1490.27,306.2,1495.53,304.93,1499.76,302.39z" />
        <path class="st1" d="M1630.13,137.45v73.1h0.81c4.85-8.15,11.05-14.09,18.61-17.8c7.55-3.71,14.92-5.56,22.1-5.56
		c10.25,0,18.65,1.4,25.22,4.21c6.56,2.81,11.72,6.7,15.5,11.69c3.78,4.98,6.42,11.05,7.95,18.2c1.53,7.16,2.29,15.08,2.29,23.78
		v86.41h-38.29v-79.35c0-11.6-1.8-20.24-5.39-25.96c-3.6-5.7-9.97-8.56-19.14-8.56c-10.43,0-17.98,3.13-22.65,9.37
		c-4.68,6.25-7.01,16.54-7.01,30.84v73.65h-38.29V137.45H1630.13z" />
        <path class="st1" d="M1801.62,190.98v26.09h0.54c1.8-4.35,4.22-8.38,7.28-12.1c3.05-3.71,6.56-6.88,10.52-9.51
		c3.95-2.63,8.17-4.66,12.67-6.11c4.49-1.45,9.17-2.17,14.02-2.17c2.51,0,5.3,0.45,8.35,1.35v35.87c-1.8-0.36-3.96-0.68-6.47-0.95
		c-2.52-0.27-4.95-0.4-7.28-0.4c-7.01,0-12.94,1.18-17.79,3.53c-4.86,2.35-8.76,5.57-11.73,9.65c-2.97,4.07-5.09,8.83-6.34,14.27
		c-1.25,5.44-1.89,11.32-1.89,17.66v63.31h-38.29V190.98H1801.62z" />
        <path class="st1" d="M1881.96,234.18c0.54-9.05,2.79-16.57,6.75-22.55c3.95-5.98,8.98-10.78,15.09-14.41
		c6.11-3.62,12.99-6.21,20.63-7.74c7.64-1.54,15.32-2.31,23.05-2.31c7.01,0,14.11,0.49,21.3,1.49c7.19,1,13.75,2.95,19.69,5.84
		c5.93,2.9,10.78,6.93,14.56,12.1c3.78,5.16,5.66,12,5.66,20.51v73.1c0,6.35,0.36,12.42,1.08,18.21c0.72,5.8,1.98,10.15,3.78,13.04
		h-38.83c-0.72-2.18-1.31-4.39-1.75-6.65c-0.44-2.27-0.76-4.58-0.94-6.94c-6.11,6.35-13.3,10.78-21.57,13.32
		c-8.27,2.54-16.72,3.81-25.35,3.81c-6.66,0-12.86-0.81-18.61-2.44c-5.76-1.63-10.78-4.16-15.1-7.61
		c-4.31-3.43-7.68-7.78-10.11-13.04c-2.42-5.25-3.64-11.5-3.64-18.75c0-7.96,1.39-14.53,4.18-19.7c2.79-5.16,6.38-9.28,10.79-12.36
		c4.4-3.08,9.44-5.39,15.1-6.93c5.66-1.54,11.37-2.76,17.12-3.67c5.76-0.91,11.41-1.63,16.99-2.18c5.57-0.54,10.52-1.35,14.83-2.44
		s7.73-2.67,10.24-4.76c2.52-2.08,3.69-5.12,3.51-9.1c0-4.16-0.67-7.48-2.03-9.92c-1.34-2.44-3.14-4.35-5.39-5.71
		c-2.25-1.35-4.86-2.26-7.82-2.71c-2.97-0.46-6.17-0.69-9.57-0.69c-7.56,0-13.49,1.63-17.8,4.9c-4.31,3.26-6.84,8.69-7.56,16.3
		H1881.96z M1970.39,262.72c-1.62,1.46-3.64,2.58-6.07,3.4c-2.42,0.81-5.03,1.49-7.82,2.04c-2.79,0.54-5.71,1-8.76,1.35
		c-3.06,0.37-6.11,0.81-9.16,1.36c-2.88,0.54-5.71,1.26-8.5,2.17c-2.79,0.92-5.21,2.13-7.27,3.67c-2.07,1.54-3.73,3.49-4.99,5.84
		c-1.25,2.36-1.89,5.35-1.89,8.97c0,3.45,0.64,6.34,1.89,8.69c1.26,2.36,2.97,4.22,5.12,5.57c2.16,1.36,4.68,2.31,7.56,2.86
		c2.87,0.54,5.84,0.81,8.9,0.81c7.55,0,13.39-1.26,17.53-3.81c4.13-2.54,7.18-5.56,9.16-9.1c1.98-3.53,3.19-7.11,3.64-10.74
		c0.44-3.62,0.67-6.53,0.67-8.69V262.72z" />
        <path class="st1"
            d="M2084.04,296.56c1.7,2.98,3.9,5.43,6.6,7.33c2.7,1.9,5.79,3.31,9.3,4.21c3.51,0.92,7.15,1.36,10.92,1.36
		c2.7,0,5.53-0.31,8.49-0.95c2.97-0.63,5.67-1.63,8.09-2.99c2.42-1.35,4.45-3.17,6.07-5.43c1.62-2.27,2.42-5.12,2.42-8.57
		c0-5.79-3.82-10.14-11.46-13.04c-7.64-2.89-18.29-5.79-31.95-8.69c-5.58-1.26-11.02-2.76-16.32-4.48
		c-5.3-1.72-10.02-3.98-14.15-6.79c-4.14-2.81-7.46-6.34-9.98-10.6c-2.52-4.26-3.78-9.46-3.78-15.63c0-9.05,1.75-16.48,5.26-22.28
		c3.51-5.8,8.14-10.37,13.89-13.73c5.75-3.35,12.21-5.7,19.41-7.07c7.19-1.35,14.57-2.03,22.11-2.03s14.87,0.72,21.98,2.17
		c7.1,1.46,13.43,3.9,19.01,7.34c5.58,3.44,10.2,8.02,13.88,13.72c3.69,5.71,5.89,12.91,6.61,21.61h-36.41
		c-0.54-7.43-3.32-12.46-8.35-15.08c-5.03-2.63-10.97-3.94-17.8-3.94c-2.15,0-4.5,0.14-7.01,0.41c-2.52,0.27-4.81,0.86-6.87,1.77
		c-2.07,0.91-3.82,2.21-5.26,3.94c-1.43,1.73-2.15,4.04-2.15,6.94c0,3.44,1.25,6.24,3.77,8.42c2.52,2.17,5.8,3.94,9.84,5.3
		c4.04,1.35,8.67,2.58,13.88,3.67c5.21,1.09,10.52,2.27,15.91,3.53c5.57,1.27,11.01,2.81,16.31,4.62s10.03,4.21,14.16,7.19
		c4.13,2.99,7.46,6.71,9.98,11.15c2.52,4.44,3.78,9.92,3.78,16.44c0,9.24-1.85,16.99-5.53,23.24c-3.69,6.25-8.49,11.28-14.43,15.08
		c-5.93,3.8-12.71,6.48-20.35,8.02c-7.65,1.54-15.42,2.31-23.33,2.31c-8.09,0-16-0.81-23.73-2.44c-7.73-1.63-14.6-4.35-20.62-8.15
		c-6.03-3.81-10.97-8.83-14.83-15.08c-3.87-6.25-5.98-14.09-6.34-23.5h36.4C2081.47,289.98,2082.33,293.56,2084.04,296.56z" />
        <path class="st1"
            d="M2251.08,297.77c5.75,5.62,14.02,8.43,24.8,8.43c7.73,0,14.38-1.95,19.95-5.85
		c5.58-3.89,8.99-8.02,10.25-12.36h33.7c-5.39,16.85-13.66,28.9-24.8,36.14c-11.15,7.25-24.64,10.87-40.45,10.87
		c-10.97,0-20.86-1.77-29.66-5.3c-8.81-3.53-16.27-8.56-22.38-15.08c-6.11-6.53-10.83-14.3-14.16-23.37
		c-3.32-9.06-4.99-19.03-4.99-29.9c0-10.5,1.71-20.28,5.12-29.34c3.41-9.06,8.26-16.89,14.57-23.51
		c6.28-6.61,13.79-11.82,22.51-15.62s18.38-5.7,28.99-5.7c11.86,0,22.19,2.31,31,6.93c8.81,4.61,16.05,10.83,21.71,18.62
		c5.66,7.79,9.74,16.67,12.27,26.62c2.51,9.97,3.41,20.38,2.69,31.25h-100.57C2242.18,283.1,2245.32,292.16,2251.08,297.77z
		 M2294.35,223.86c-4.59-5.07-11.55-7.61-20.9-7.61c-6.11,0-11.19,1.04-15.24,3.12c-4.04,2.09-7.27,4.67-9.71,7.74
		c-2.42,3.08-4.13,6.34-5.12,9.78c-0.99,3.44-1.57,6.53-1.75,9.24h62.29C2302.12,236.36,2298.94,228.93,2294.35,223.86z" />
    </g>
    <g>
        <path class="st1" d="M2359.53,155.87c2.32-5.13,5.43-9.49,9.34-13.1c3.9-3.61,8.43-6.39,13.55-8.37c5.12-1.97,10.49-2.96,16.1-2.96
		c5.6,0,10.96,0.99,16.09,2.96c5.12,1.98,9.64,4.76,13.55,8.37c3.91,3.61,7.03,7.97,9.34,13.1c2.32,5.13,3.49,10.85,3.49,17.17
		c0,6.4-1.16,12.15-3.49,17.28c-2.32,5.13-5.44,9.52-9.34,13.16c-3.9,3.64-8.43,6.42-13.55,8.36c-5.13,1.93-10.49,2.9-16.09,2.9
		c-5.61,0-10.98-0.97-16.1-2.9s-9.64-4.71-13.55-8.36c-3.91-3.64-7.03-8.03-9.34-13.16c-2.32-5.13-3.49-10.88-3.49-17.28
		C2356.05,166.72,2357.21,161,2359.53,155.87z M2369.76,186.19c1.63,4.02,3.84,7.5,6.64,10.42c2.81,2.94,6.1,5.23,9.9,6.86
		c3.81,1.63,7.87,2.45,12.23,2.45c4.27,0,8.31-0.82,12.1-2.45c3.8-1.64,7.1-3.93,9.91-6.86c2.8-2.93,5.03-6.41,6.69-10.42
		c1.65-4.01,2.49-8.39,2.49-13.15s-0.84-9.12-2.49-13.1c-1.66-3.98-3.9-7.41-6.69-10.31c-2.81-2.9-6.1-5.16-9.91-6.8
		c-3.8-1.64-7.84-2.45-12.1-2.45c-4.36,0-8.43,0.81-12.23,2.45c-3.8,1.64-7.09,3.9-9.9,6.8c-2.8,2.9-5.01,6.33-6.64,10.31
		c-1.62,3.97-2.43,8.34-2.43,13.1S2368.14,182.18,2369.76,186.19z M2399.52,149.63c5.83,0,10.31,1.04,13.44,3.12
		c3.13,2.09,4.7,5.65,4.7,10.7c0,2.16-0.32,4-0.94,5.52c-0.63,1.53-1.49,2.77-2.6,3.74c-1.11,0.96-2.4,1.7-3.87,2.22
		c-1.48,0.52-3.06,0.86-4.76,1.01l12.39,20.4h-9.63l-11.28-19.95h-5.75v19.95h-9.18v-46.71H2399.52z M2399.41,169.14
		c2.65,0,4.83-0.38,6.53-1.12c1.69-0.74,2.54-2.53,2.54-5.35c0-1.26-0.3-2.26-0.89-3.01c-0.59-0.74-1.34-1.32-2.26-1.72
		c-0.93-0.41-1.96-0.69-3.1-0.84c-1.14-0.15-2.23-0.22-3.27-0.22h-7.74v12.26H2399.41z" />
    </g>
</svg>