import { ThemePalette } from '@angular/material/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cp-icon',
    templateUrl: './catchphrase-icon.component.html',
    styleUrls: ['./catchphrase-icon.component.scss']
})
export class CatchphraseIconComponent {
    @Input() name!: string;
    @Input() color: IconColor;
    @Input() hoverColor: IconColor;
    @Input() size: IconSize = 'medium';
    @Input() strokeSize: StrokeSize = 'default';
    @Input() fill: IconColor;
    @Input() shake = false;
    @Input() rotated = false;

    getClass(): { [klass: string]: boolean } {
        return {
            'icon-xsmall': this.size === 'x-small',
            'icon-small': this.size === 'small',
            'icon-medium': this.size === 'medium',
            'icon-large': this.size === 'large',
            'stroke-small': this.strokeSize === 'small',
            'fill-warn': this.fill === 'warn',
            'fill-white': this.fill === 'white',
            'fill-current': this.fill === 'currentColor'
        };
    }

    getMatColor(): ThemePalette {
        if (this.color as ThemePalette) {
            return this.color as ThemePalette;
        } else {
            return undefined;
        }
    }

    getMatClass(): { [klass: string]: boolean } {
        return {
            'icon-xsmall': this.size === 'x-small',
            'icon-small': this.size === 'small',
            'icon-medium': this.size === 'medium',
            'icon-large': this.size === 'large',
            transparent: this.color === 'transparent',
            'white-transparent': this.color === 'white-transparent',
            white: this.color === 'white',
            'hover-transparent': this.hoverColor === 'transparent',
            'hover-white-transparent': this.hoverColor === 'white-transparent',
            'hover-white': this.hoverColor === 'white',
            'blue-grey': this.color === 'blue-grey',
            grey: this.color === 'grey',
            'dark-grey': this.color === 'dark-grey',
            'hover-accent': this.hoverColor === 'accent',
            'hover-primary': this.hoverColor === 'primary',
            'hover-warn': this.hoverColor === 'warn'
        };
    }
}

export type IconSize = 'x-small' | 'small' | 'medium' | 'large';
export type IconColor = ThemePalette | 'transparent' | 'white-transparent' | 'white' | 'blue-grey' | 'grey' | 'dark-grey' | 'currentColor';
export type StrokeSize = 'small' | 'default';
