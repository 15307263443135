import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { StringGenerator, UrlResolver } from '@core/utils';
import { UserClaims } from '@core/models';
import { Injectable, NgZone } from '@angular/core';
import { UserManager, User } from 'oidc-client-ts';
import { userManagerSettingsFactory } from '@core/factories';
import { EndPoint } from '@core/constants';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private _userLoaded = new Subject<User>();
    userLoaded$ = this._userLoaded.asObservable();

    private manager!: UserManager;
    private user: User | null = null;

    constructor(private http: HttpClient, zone: NgZone) {
        zone.runOutsideAngular(() => {
            this.manager = new UserManager(userManagerSettingsFactory());

            this.manager.getUser().then(user => {
                this.user = user;
            });

            // Ensure we update the user after a renew event.
            this.manager.events.addUserLoaded(() => {
                this.manager.getUser().then(user => {
                    this.user = user;
                    if (user) {
                        this._userLoaded.next(user);
                    }
                });
            });
        });
    }

    get hasSession(): boolean {
        return !!this.user;
    }

    get hasActiveSession(): boolean {
        return !!this.user && !this.user.expired;
    }

    get claims(): UserClaims | undefined {
        return this.user?.profile;
    }

    get userId(): string | undefined {
        return this.claims?.sub;
    }

    get accessToken(): string | undefined {
        return this.user?.access_token;
    }

    async isLoggedIn(): Promise<boolean> {
        if (this.user === null) {
            this.user = await this.manager.getUser();
        }
        return this.user !== null && !this.user.expired;
    }

    async attemptSilentSignIn(): Promise<User | null> {
        const user = await this.manager.signinSilent();

        if (user) {
            this.user = user;
        }

        return user;
    }

    startAuthentication(returnRoute?: string): Promise<void> {
        let params = {};

        // If we need a return route set it here.
        if (returnRoute) {
            const state = StringGenerator.generateRandomString();
            params = { state };
            localStorage.setItem(state, returnRoute);
        }

        return this.manager.signinRedirect(params);
    }

    proxySignIn(organisationId: number): void {
        const endpoint = UrlResolver.getUrl(EndPoint.account, 'proxy/login');
        this.http.post(endpoint, { organisationId }).pipe(take(1)).subscribe({
            next: () => { this.startAuthentication().then(() => { }); },
            error: () => { }
        });
    }

    async completeAuthentication(): Promise<string> {
        const user = await this.manager.signinRedirectCallback();
        this.user = user;

        // Check if a return route was stored for the state.
        if (user.state) {
            const returnRoute = localStorage.getItem(user.state as string);
            localStorage.removeItem(user.state as string);
            if (returnRoute) {
                return returnRoute;
            }
        }

        return '';
    }

    revokeAuthentication(): Promise<void> {
        return this.manager.signoutRedirect();
    }

    clearStaleUser(): Promise<void> {
        return this.manager.removeUser();
    }

    isInAnyRole(roles: string[]): boolean {
        return roles.some(role => this.claims?.role === role);
    }
}
