import { TextColor } from './../../organisations/models/whitelabel-registration.model';

export function calculateTextColor(color: string): TextColor {
    const rgb = HexToRGB(color);

    if (rgb) {
        const luminance = 0.2126 * _getLuminanceColor(rgb.red)
            + 0.7152 * _getLuminanceColor(rgb.green)
            + 0.0722 * _getLuminanceColor(rgb.blue);

        return luminance > 0.179
            ? 'Dark'
            : 'Light';
    }
    return 'Dark';
}

function _getLuminanceColor(intensity: number): number {
    const color = intensity / 255.0;

    return color <= 0.03928
        ? color / 12.92
        : ((color + 0.055) / 1.055) ** 2;
}

function HexToRGB(hex: string): RGB | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? new RGB(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ) : null;
}

class RGB {
    constructor(
        public red: number,
        public green: number,
        public blue: number,
    ) { }

    get toString(): string {
        return `rgb(${this.red}, ${this.green}, ${this.blue})`;
    }

    calculateMidPoint(otherColour: RGB, percentage: number): RGB {
        const r = this._calculateIndividualMidPoint(this.red, otherColour.red, percentage);
        const g = this._calculateIndividualMidPoint(this.green, otherColour.green, percentage);
        const b = this._calculateIndividualMidPoint(this.blue, otherColour.blue, percentage);
        return new RGB(r, g, b);
    }

    private _calculateIndividualMidPoint(color1: number, color2: number, percentage: number): number {
        const scale = color2 - color1;
        return color1 + Math.round(scale * (percentage / 100));
    }
}
