import { NgModule } from '@angular/core';

import { FeatherModule } from '@feather/feather.module';

import {
    Copy, Trash, Plus, Check, Home,
    Save, Settings, ChevronLeft, ChevronRight,
    Menu, HelpCircle, X, ZoomIn, Upload,
    RotateCcw, Video, List, Image,
    ZoomOut, ArrowUp, ArrowDown, ArrowLeft,
    ArrowRight, AlertTriangle, XOctagon, Edit3, Move,
    AlignCenter, AlignJustify, AlignLeft, AlignRight,
    VideoOff, PlayCircle, ArrowRightCircle, MoreHorizontal,
    ChevronDown, ChevronUp, Info, Grid, Edit, Star,
    Calendar, MapPin, Bell, AlertCircle, Phone, Mail, Clock,
    Filter, Flag, FileText, UploadCloud, Clipboard, Layout,
    Users, User, Mic, CheckSquare, Search, RefreshCw, Send,
    File, Lock, Link, Archive, Eye, ArrowDownCircle, RefreshCcw,
    Smartphone, Printer, LogIn,
    Text, OrderedList, Subtitle, FileCheck, Shuffle, Download
} from '@shared/icon/icons';

// Select some icons (use an object, not an array)
const icons = {
    Copy, Trash, Plus, Check,
    Home, Save, Settings, ChevronLeft,
    ChevronRight, Menu, RotateCcw, Video,
    HelpCircle, X, ZoomIn, Upload,
    List, Image, Text, ZoomOut,
    ArrowUp, ArrowDown, ArrowLeft, ArrowRight,
    AlertTriangle, XOctagon, Edit3, Move,
    AlignCenter, AlignJustify, AlignLeft, AlignRight,
    OrderedList, Subtitle, VideoOff, PlayCircle,
    ArrowRightCircle, MoreHorizontal,
    ChevronDown, ChevronUp, Info, Grid, Edit, Star,
    Calendar, MapPin, Bell, AlertCircle, Phone, Mail,
    Clock, Filter, FileCheck, Flag, FileText, UploadCloud,
    Clipboard, Layout, Users, User, Mic, CheckSquare,
    Search, RefreshCw, Send, File, Lock, Link,
    Archive, Eye, ArrowDownCircle, RefreshCcw,
    Smartphone, Printer, LogIn, Shuffle, Download
};

@NgModule({
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ],
    declarations: []
})
export class IconModule { }
