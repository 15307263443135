import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export class ConfirmDialogData {
    constructor(
        public message: string,
        public continueColor: ThemePalette | undefined = 'primary',
        public continueAndColor: ThemePalette | undefined = 'primary',
        public continueAnd = false,
        public confirmText = 'Continue',
        public cancelText = 'Cancel',
        public continueAndText = 'Save and continue',
        public confirmKeyDelay = 0,
        public cancel = true,
    ) {}
}

export type ConfirmDialogResult = 'cancel' | 'continue' | 'continueAnd';

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    counter = 0;
    disabled = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

    ngOnInit(): void {
        if (this.data.confirmKeyDelay > 0) {
            this.disabled = true;
            this.counter = Math.floor(this.data.confirmKeyDelay);
            this._countDown();
        }
    }

    private _countDown(): void {
        if (this.counter !== 0) {
            setTimeout( () => { this._countDown(); }, 1000);
            this.counter--;
        } else {
            this.disabled = false;
        }
    }
}
