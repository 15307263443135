import {
    AuthenticationService,
    AlertService,
    GeneralDataService,
    SettingsDataService,
    GlobalsService,
} from '@core/services';
import { AuthorizationGuard, EditorDeactivateGuard } from '@core/guards';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { jwtOptionsFactory } from '@core/factories';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMATS } from '@core/constants';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { LocalizedDatePipe, SecurePipe } from '@shared/pipes';
import { DateHttpInterceptor, SessionHttpInterceptor } from './interceptors';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                deps: [AuthenticationService],
                useFactory: jwtOptionsFactory
            }
        })
    ],
    providers: [
        AuthorizationGuard,
        GeneralDataService,
        AlertService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: DateHttpInterceptor
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: SessionHttpInterceptor,
            deps: [
                AuthenticationService,
                AlertService,
                MatDialog,
                Router
            ]
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: DateAdapter,
            useValue: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMATS,
        },
        LocalizedDatePipe,
        EditorDeactivateGuard,
        SecurePipe,
        SettingsDataService,
        GlobalsService,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('Core Module has already been loaded.');
        }
    }
}
