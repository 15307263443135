<header class="w-100 container-fluid">
    <div class="row d-flex align-items-center h-100">
        <div class="ml-5 logo">
            <cp-header-logo></cp-header-logo>
        </div>
        <nav class="mr-4">
            <ul class='header-list'>
                <li *ngFor='let route of activeRoutes' class='d-none d-lg-flex'>
                    <a class='nav-link' [routerLink]='route.route' [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{ exact: route.exact || false }">
                        <span>
                            {{ route.title }}
                        </span>
                    </a>
                </li>
                <li class='icon-buttons'>
                    <a class='nav-link' [routerLink]='["/settings"]' [routerLinkActive]="['active']">
                        <cp-icon size='small' name='settings'></cp-icon>
                    </a>
                </li>
                <li class='d-none d-lg-flex lg-last'>
                    <a (click)='signOut()' class='cursor-pointer nav-link'>
                        <span>
                            Sign out
                        </span>
                    </a>
                </li>
                <li class='d-flex d-lg-none icon-buttons'>
                    <a mat-icon-button (click)='sidenavOpened = !sidenavOpened'>
                        <cp-icon size='small' [name]='sidenavOpened ? "x" : "menu"'></cp-icon>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</header>

<div class="nav-container" [class.shown]='sidenavOpened' (click)='onContainerClick($event)'>
    <aside class='side-nav' [class.shown]='sidenavOpened'>
        <ul>
            <li *ngFor='let route of routes'>
                <a class='nav-link' [routerLink]='route.route' [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{ exact: route.exact || false }">
                    <span>
                        {{ route.title }}
                    </span>
                </a>
            </li>
            <li>
                <a (click)='signOut()' class='cursor-pointer nav-link'>
                    <span>
                        Sign out
                    </span>
                </a>
            </li>
        </ul>
        <div class='logo-container'>
            <cp-header-logo></cp-header-logo>
        </div>
    </aside>
</div>
