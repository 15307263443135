import { PipeTransform, Pipe } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EMPTY_IMAGE } from '@core/constants/images';

@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient) { }

    transform(url: string): Observable<string> {
        return new Observable<string>((observer) => {
            // Prevent image from having undefined src which would cause it to throw an error
            observer.next(EMPTY_IMAGE);

            this.http.get(url, { responseType: 'blob' }).subscribe(response => {
                observer.next(URL.createObjectURL(response));
            }, (_) => {
                // If backend call fails, resolve to undefined so the img throws an error
                observer.next(undefined);
            });

            return { unsubscribe(): void { } };
        });
    }
}
