import { AuthenticationService } from '@core/services';

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './sign-in-callback.component.html'
})
export class CallbackComponent implements OnInit, AfterViewInit, OnDestroy  {
    constructor(private authService: AuthenticationService, private router: Router) {
    }

    ngOnInit(): void {
        this.authService.completeAuthentication()
            .then((returnRoute) => {
                if (!returnRoute) {
                    this.router.navigate(['']);
                } else {
                    try {
                        const url = new URL(returnRoute, window.location.origin);
                        // Necessary any's because query parameters are of unknown signature
                        const params = {} as any;
                        for (const [key, value] of (url.searchParams as any).entries()) {
                            params[key] = value;
                        }
                        this.router.navigate([url.pathname], { queryParams: params})
                            .catch(_ => this.router.navigate(['']));
                    }
                    catch {
                        this.router.navigate(['']);
                    }
                }
            })
            .catch(() => {
                this.router.navigate(['']);
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('body')?.classList.add('cp-dark');
    }

    ngOnDestroy(): void {
        document.querySelector('body')?.classList.remove('cp-dark');
    }
}
