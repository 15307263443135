import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'cp-minimal-paginator',
    templateUrl: './minimal-paginator.component.html',
    styleUrls: ['./minimal-paginator.component.scss']
})
export class MinimalPaginatorComponent implements OnDestroy {
    @Input()
        currentPage = 0;

    @Input()
        pageSize = 0;

    @Input()
        total = 0;

    @Output()
        pageForward = new EventEmitter<void>();

    @Output()
        pageBackward = new EventEmitter<void>();

    get page(): number {
        return this.currentPage + 1;
    }

    get totalPages(): number {
        if (this.pageSize === 0) {
            return 0;
        }

        return Math.ceil(this.total / this.pageSize);
    }

    get enableNextPage(): boolean {
        return this.page < this.totalPages;
    }

    get enablePreviousPage(): boolean {
        return this.page > 1;
    }

    ngOnDestroy(): void {
        this.pageForward.unsubscribe();
        this.pageBackward.unsubscribe();
    }

    onNextPageClick(): void {
        if (!this.enableNextPage) {
            return;
        }

        this.pageForward.emit();
    }

    onPreviousPageClick(): void {
        if (!this.enablePreviousPage) {
            return;
        }

        this.pageBackward.emit();
    }
}
