import { Component } from '@angular/core';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '@core/constants';
import { AuthenticationService } from '@core/services';

@Component({
    selector: 'cp-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    routes: NavItem[] = [
        {
            title: 'Translations',
            route: ['translations'],
            roles: [SUPER_ADMIN, SYSTEM_ADMIN]
        },
        {
            title: 'Organisations',
            route: ['organisations']
        },
        {
            title: 'System',
            route: ['system'],
            roles: [SYSTEM_ADMIN]
        },
        {
            title: 'Account Management',
            route: ['users'],
            roles: [SUPER_ADMIN, SYSTEM_ADMIN]
        }];

    sidenavOpened = false;

    constructor(
        private auth: AuthenticationService
    ) { }

    get activeRoutes(): NavItem[] {
        return this.routes.filter(x => x.roles ? this.isInAnyRole(x.roles) : true);
    }

    onContainerClick(event: MouseEvent): void {
        // If we clicked on the container we close the navigation menu.
        const el = event.target as HTMLElement;
        if (el && el.classList.contains('nav-container')) {
            this.sidenavOpened = false;
        }
    }

    signOut(): void {
        this.auth.revokeAuthentication();
    }

    isInAnyRole(roles: string[]): boolean {
        return this.auth.isInAnyRole(roles);
    }
}

export interface NavItem {
    title: string;
    route: string[];
    fragment?: string;

    // If not null, only the given roles can see this item
    roles?: string[];

    // Prevents empty or subroutes from being active when they're not supposed to be
    exact?: boolean;
}
