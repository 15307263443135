import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class EditorDeactivateGuard  {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        return component.canDeactivate()
            ? true
            : confirm('You may have unsaved changes, are you sure you want to continue?');
    }
}
