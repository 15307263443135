import { Component, OnInit } from '@angular/core';
import { HomeEventModel } from '@core/models';
import { GeneralDataService } from '@core/services';
import { take } from 'rxjs';

@Component({
    templateUrl: './active-events-overview.component.html',
    styleUrls: ['./active-events-overview.component.scss']
})
export class ActiveEventsOverviewComponent implements OnInit {

    events: HomeEventModel[] = [];

    loading = true;
    errorLoading = false;

    constructor(
        private data: GeneralDataService
    ) { }

    ngOnInit(): void {
        this.data.getActiveEventsOverview().pipe(take(1)).subscribe({
            next: (response) => {
                this.events = response;
                this._initializeStatusses();
                this.loading = false;
            }, error: () => {
                this.errorLoading = true;
                this.loading = false;
            }
        });
    }

    getStatusTooltip(event: HomeEventModel): string {
        if (event.isOngoing) {
            return 'Ongoing event';
        }

        switch(event.status) {
        case 'upcoming': return 'Upcoming event';
        case 'active': return 'Active event';
        case 'evaluation': return 'Event in evaluation period';
        default: return '';
        }
    }

    private _initializeStatusses(): void {
        this.events.forEach(x => {
            x.status = this._getStatus(x);
        });
    }

    private _getStatus(event: HomeEventModel): string {
        if (event.isOngoing) {
            return 'active';
        }

        const now = new Date();
        if (event.eventDate && now < event.eventDate) { return 'upcoming'; }
        else if (event.endDate && now < event.endDate) { return 'active'; }
        else if (event.evaluationEndDate && now < event.evaluationEndDate) { return 'evaluation'; }

        return 'inactive';
    }
}
