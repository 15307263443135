// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    envName: 'dev',
    local: false,
    production: false,
    apiLink: 'https://catchphrase-dev.westeurope.cloudapp.azure.com/api',
    host: 'https://admin.dev.catchphrase.io',
    app: 'https://catchphrase-dev.westeurope.cloudapp.azure.com',
    cms: 'https://catchphrase-dev.westeurope.cloudapp.azure.com/cms',
    whiteListJwt: [
        'admin.dev.catchphrase.io',
        'catchphrase-dev.westeurope.cloudapp.azure.com'
    ],
    enableIpFirewall: true,
    whitelistedIps: ['37.120.141.164']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
