import { AccountSettings, EnableTwoFAModel, TwoFARecoveryCodesModel } from '../models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GeneralDataService } from '@core/services';
import { EndPoint } from '@core/constants';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class SettingsDataService extends GeneralDataService {
    getAccountSettings(): Observable<AccountSettings> {
        return this.getGenericItem<AccountSettings>(EndPoint.account, 'settings');
    }

    setAccountSettings(settings: AccountSettings): Observable<unknown> {
        return this.postGenericItem(EndPoint.account, 'settings/admin', settings);
    }

    enableTwoFa(): Observable<EnableTwoFAModel> {
        return this.postGenericItem(EndPoint.account, 'settings/2fa/enable', {});
    }

    verifyTwoFa(code: string): Observable<TwoFARecoveryCodesModel> {
        return this.postGenericItem(EndPoint.account, `settings/2fa/enable/verify`, { code });
    }

    getTwoFaQR(key: string): Observable<HttpResponse<Blob>> {
        return this.getGenericFileFromPost(EndPoint.account, `settings/2fa/enable/qr`, { key })
    }

    disableTwoFa(code: string): Observable<boolean> {
        return this.postGenericItem(EndPoint.account, 'settings/2fa/disable', { code });
    }
}
