export class DateUtils {
    public static IsOnSameDay(d1: Date, d2: Date): boolean {
        return d1.getDate() === d2.getDate()
            && d1.getMonth() === d2.getMonth()
            && d1.getFullYear() === d2.getFullYear();
    }

    public static IsLaterDate(d1: Date, d2: Date): boolean {
        return d1.getFullYear() > d2.getFullYear() ||
            (d1.getFullYear() === d2.getFullYear() && d1.getMonth() > d2.getMonth()) ||
            (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() > d2.getDate());
    }

    public static CalculateDifferenceBetweenDates(date1: Date, date2: Date): number {
        if (!date1.getTime) {
            date1 = new Date(date1 + 'Z');
        }

        if (!date2.getTime) {
            date2 = new Date(date2 + 'Z');
        }

        const date1Timestamp = date1.getTime();
        const date2Timestamp = date2.getTime();
        const diff = date1Timestamp - date2Timestamp;

        return diff;
    }

    public static CalculateDifferenceInDaysBetweenDates(date1: Date, date2: Date): number {
        const diff = this.CalculateDifferenceBetweenDates(date1, date2);

        // Calculate the difference divided by the amount of milliseconds in a day.
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

        return days;
    }

    public static SortDates(date1: Date | undefined, date2: Date | undefined, checkSeconds1: boolean, checkSeconds2: boolean): number {
        let date1ToCheck: Date | undefined;
        if (date1) {
            date1ToCheck = new Date(date1);

            // If seconds should be ignored set them to 0 so they will not be taken into account.
            if (!checkSeconds1) {
                date1ToCheck.setSeconds(0);
            }
        }

        let date2ToCheck: Date | undefined;
        if (date2) {
            date2ToCheck = new Date(date2);

            // If seconds should be ignored set them to 0 so they will not be taken into account.
            if (!checkSeconds2) {
                date2ToCheck.setSeconds(0);
            }
        }

        return (date1ToCheck?.getTime() || Number.MAX_SAFE_INTEGER) - (date2ToCheck?.getTime() || Number.MAX_SAFE_INTEGER);
    }

    public static SortTime(date1: Date | undefined, date2: Date | undefined, checkSeconds1: boolean, checkSeconds2: boolean): number {
        // Ensure both dates have the same date so that only the time is a factor.
        const timeDate1 = new Date();
        const timeDate2 = new Date(timeDate1);

        if (date1) {
            timeDate1.setHours(date1.getHours());
            timeDate1.setMinutes(date1.getMinutes());
            // Only set seconds if required, otherwise default to 0.
            timeDate1.setSeconds(checkSeconds1 ? date1.getSeconds() : 0);
        }

        if (date2) {
            timeDate2.setHours(date2.getHours());
            timeDate2.setMinutes(date2.getMinutes());
            // Only set seconds if required, otherwise default to 0.
            timeDate2.setSeconds(checkSeconds2 ? date2.getSeconds() : 0);
        }

        // If dates are undefined just pass the undefined value, sort dates knows how to handle this.
        return this.SortDates(date1 ? timeDate1 : date1, date2 ? timeDate2 : date2, checkSeconds1, checkSeconds2);
    }
}
