import {
    Component,
    ElementRef,
    Input,
    Inject,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Icons } from './icons.provider';
import { uppercamelcase } from './utils';

@Component({
    selector: 'cp-feather',
    templateUrl: './feather.component.html',
    styleUrls: ['./feather.component.scss'],
})
export class FeatherComponent implements OnChanges {
  @Input() name!: string;

  constructor(
    @Inject(ElementRef) private elem: ElementRef,
    @Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
    @Inject(Icons) private icons: Icons
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
  	// icons are provided as an array of objects because of "multi: true"
  	const icons = Object.assign({}, ...(this.icons as any as object[]));
  	const svg = icons[uppercamelcase(changes.name.currentValue)] || '';

  	if (!svg) {
  		console.warn(
  			`Icon not found: ${changes.name.currentValue}`
  		);
  	}

  	this.elem.nativeElement.innerHTML = svg;
  	this.changeDetector.markForCheck();
  }
}
