<mat-dialog-content class="mat-typography mx-3 mb-3">
    <span>
        {{ data.message }}
    </span>
</mat-dialog-content>
<mat-dialog-actions align='end'>
    <button *ngIf='data.cancel' mat-button class="cp-button" mat-dialog-close mat-dialog-close='cancel'>
        <span>
            {{ data.cancelText }}
        </span>
    </button>
    <button mat-flat-button class="cp-button" mat-dialog-close mat-dialog-close='continue' [color]='data.continueColor'
        [disabled]='disabled'>
        <span *ngIf='!disabled; else countdown'>
            {{ data.confirmText }}
        </span>
    </button>
    <button *ngIf='data.continueAnd' mat-flat-button class="cp-button" mat-dialog-close='continueAnd'
        [color]='data.continueAndColor' [disabled]='disabled'>
        <span *ngIf='!disabled; else countdown'>
            {{ data.continueAndText }}
        </span>
    </button>
</mat-dialog-actions>

<ng-template #countdown>
    <span>
        {{ counter + 1 }}...
    </span>
</ng-template>
