import { AuthenticationService } from '@core/services';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { TwofaRequiredDialogComponent } from '@shared/components';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Admin - Catchphrase';

    private _destroy$ = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private auth: AuthenticationService,
        private dialog: MatDialog,
    ) { }

    get isWebkit(): boolean {
        return navigator.userAgent.indexOf('Chrome') !== -1
            || navigator.userAgent.indexOf('Safari') !== -1
            || navigator.userAgent.indexOf('Opera') !== -1;
    }

    get isNotMacChrome(): boolean {
        return navigator.userAgent.indexOf('Chrome') !== -1
            && navigator.userAgent.indexOf('Macintosh') === -1;
    }

    ngOnInit(): void {
        this._checkIp();

        // Perform the first check now, or whenever the user has finished loading in
        this.auth.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                this._checkTwoFactorRequired();
            } else {
                this.auth.userLoaded$.pipe(first(user => !!user), take(1)).subscribe(
                    (user) => {
                        if (user) {
                            this._checkTwoFactorRequired();
                        }
                    }
                );
            }
        });
        // Afterwards, perform the check daily
        const everyDay$ = interval(24 * 60 * 60 * 1000);
        everyDay$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            this._checkTwoFactorRequired();
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    private _checkIp(): void {
        if (environment.enableIpFirewall) {
            // todo: change to catchphrase endpoint once admin api is built
            this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
                if (!environment.whitelistedIps.includes(res.ip)) {
                    const value =
                        confirm('You logged in to the admin panel from an invalid IP.' +
                            'Please use your company vpn to connect. You will be logged out.');
                    this.auth.revokeAuthentication();
                }
            });
        }
    }

    private _checkTwoFactorRequired(): void {
        if (!this.auth.claims?.twofactor_enabled) {
            this.dialog.open(TwofaRequiredDialogComponent, {
                panelClass: 'small-dialog',
                disableClose: true
            });
        }
    }
}
