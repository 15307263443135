<div class="title">
    Active events
</div>
<div class="m-3">
    <div *ngIf="loading; else errorRef" class="loading-container">
        <cp-delayed-spinner></cp-delayed-spinner>
    </div>
    <ng-template #errorRef>
        <div *ngIf="errorLoading; else contentRef" class="alert alert-danger">
            Something went wrong loading the active events overview. Please try again later.
        </div>
    </ng-template>
    <ng-template #contentRef>
        <div class="header row">
            <div class="col-2">
                Name
            </div>
            <div class="col-2">
                Start date
            </div>
            <div class="col-2">
                End date
            </div>
            <div class="col-2">
                Evaluation end date
            </div>
            <div class="col-2">
                Archive date
            </div>
            <div class="col-2">
                Organisation
            </div>
        </div>
        <div *ngFor="let event of events" class="row">
            <div class="col-2">
                <div class="indicator mr-2" [ngClass]="event.status" [matTooltip]="getStatusTooltip(event)"></div>
                <span>
                    {{ event.name }}
                </span>
            </div>
            <div class="col-2">
                <span *ngIf="!event.isOngoing; else ongoingRef">
                    {{ event.eventDate | date: 'mediumDate' }}
                </span>
                <span *ngIf="event.hasTime">
                    &nbsp;{{ event.eventDate | date: 'shortTime' }}
                </span> 
                <ng-template #ongoingRef>
                    <span>
                        Ongoing
                    </span>
                </ng-template>
            </div>
            <div class="col-2">
                <span *ngIf="event.endDate; else emptyRef">
                    {{ event.endDate | date: 'mediumDate' }}
                </span>
            </div>
            <div class="col-2">
                <span *ngIf="event.evaluationEndDate; else emptyRef">
                    {{ event.evaluationEndDate | date: 'mediumDate' }}
                </span>
            </div>
            <div class="col-2">
                <span *ngIf="event.archiveDate; else emptyRef">
                    {{ event.archiveDate | date: 'mediumDate' }}
                </span>
            </div>
            <div class="col-2">
                <span>
                    {{ event.organisationName }}
                </span>
            </div>
        </div>
    </ng-template>
    <ng-template #emptyRef>
        <span>
            -
        </span>
    </ng-template>
</div>