<div class='d-flex justify-content-center'>
    <button class='page-button' [class.disabled]='!enablePreviousPage' [disabled]='!enablePreviousPage'
        (click)='onPreviousPageClick()'>
        <cp-icon name='arrow-left' size='x-small'></cp-icon>
    </button>
    <div class='page-indicator mx-1'>
        <span class='current-page'>
            {{ page }}
        </span>
        /
        <span>
            {{ totalPages }}
        </span>
    </div>
    <button class='page-button' [class.disabled]='!enableNextPage' [disabled]='!enableNextPage'
        (click)='onNextPageClick()'>
        <cp-icon name='arrow-right' size='x-small'></cp-icon>
    </button>
</div>
