import { CallbackComponent } from './sign-in-callback';
import { AuthorizationGuard } from '@core/guards';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActiveEventsOverviewComponent } from './components';


const routes: Routes = [
    {
        path: 'sign-in/callback',
        component: CallbackComponent
    },
    {
        path: '',
        canActivate: [AuthorizationGuard],
        component: ActiveEventsOverviewComponent,
        pathMatch: 'full'
    },
    {
        path: '',
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'translations',
                loadChildren: () => import('./translations/translations.module').then(m => m.TranslationsModule)
            },
            {
                path: 'organisations',
                loadChildren: () => import('./organisations/organisations.module').then(m => m.OrganisationsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'system',
                loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
