import { environment } from '@env';
import { END_USER, MANAGER } from '@core/constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@core/services';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard  {
    constructor(private auth: AuthenticationService) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return from(this.auth.isLoggedIn()).pipe(
            map((authorized: boolean) => {
                if (authorized) {
                    if (this.auth.claims?.role === END_USER) {
                        this.auth.clearStaleUser().then(() => {
                            window.location.href = environment.app;
                        });
                        return false;
                    }

                    if (this.auth.claims?.role === MANAGER) {
                        this.auth.clearStaleUser().then(() => {
                            window.location.href = environment.cms;
                        });
                        return false;
                    }

                    return true;
                } else {
                    this.auth.startAuthentication(state.url);
                    return false;
                }
            })
        );
    }
}
