import { AuthenticationService } from '@core/services';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
    public userClaims: any;
    public userKeys: string[] = [];
    public token: string | undefined;

    constructor(private auth: AuthenticationService) {
    }

    ngOnInit(): void {
        this.userClaims = this.auth.claims;
        this.userKeys = Object.keys(this.userClaims);
        this.token = this.auth.accessToken;
    }
}
