import { environment } from '@env';

export const endpoints = {
    account: environment.apiLink + '/account',
    briefing: environment.apiLink + '/briefing',
    identity: environment.apiLink + '/identity',
    translation: environment.apiLink + '/translation',
    image: environment.apiLink + '/image',
    organisation: environment.apiLink + '/organisation',
    events: environment.apiLink + '/events',
    wiki: environment.apiLink + '/wiki',
    checklist: environment.apiLink + '/checklists',
    users: environment.apiLink + '/usermanagement',
    chat: environment.apiLink + '/chat',
    tasks: environment.apiLink + '/user-tasks',
    notifications: environment.apiLink + '/notifications',
    admin: environment.apiLink + '/admin',
};
