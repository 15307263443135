import {
    ConfirmDialogComponent, DelayedSpinnerComponent, HeaderComponent,
    HeaderLogoComponent, MinimalPaginatorComponent, ToggleablePasswordInputFieldComponent,
    TwofaRequiredDialogComponent,
} from '@shared/components';
import { MatDialogModule } from '@angular/material/dialog';
import { SecurePipe, LocalizedDatePipe } from '@shared/pipes';
import { RouterModule } from '@angular/router';
import { MatButtonLoadingDirective } from '@shared/directives';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconModule, CatchphraseIconComponent } from '@shared/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OverlayModule } from '@angular/cdk/overlay';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    declarations: [
        MatButtonLoadingDirective,
        CatchphraseIconComponent,
        SecurePipe,
        LocalizedDatePipe,
        ConfirmDialogComponent,
        HeaderComponent,
        HeaderLogoComponent,
        MinimalPaginatorComponent,
        DelayedSpinnerComponent,
        ToggleablePasswordInputFieldComponent,
        TwofaRequiredDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatCheckboxModule,
        ScrollingModule,
        DragDropModule,
        IconModule,
        LayoutModule,
        RouterModule,
        OverlayModule,
        MomentDateModule,
        MatTabsModule,
        MatDatepickerModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonLoadingDirective,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        ScrollingModule,
        IconModule,
        CatchphraseIconComponent,
        LayoutModule,
        MatSnackBarModule,
        LayoutModule,
        SecurePipe,
        LocalizedDatePipe,
        MomentDateModule,
        MatDialogModule,
        ConfirmDialogComponent,
        HeaderComponent,
        MinimalPaginatorComponent,
        DelayedSpinnerComponent,
        ToggleablePasswordInputFieldComponent,
        MatTabsModule,
        MatDatepickerModule,
        TwofaRequiredDialogComponent,
    ]
})
export class SharedModule { }
