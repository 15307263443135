import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {
    private _twofaChanged = new Subject<boolean>();
    twofaChanged$ = this._twofaChanged.asObservable();

    twoFactorChanged(enabled: boolean): void {
        this._twofaChanged.next(enabled);
    }
}