<h2 mat-dialog-title class='text-left'>
    <span>
        Two-factor authentication is now required!
    </span>
</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="pageIndex === 0; else pageTwoRef">
        <div class='explainer mb-3'>
            <span>
                All admin accounts now require two-factor authentication to be enabled.
                You currently do not have two-factor authentication enabled, and will be blocked from accessing your
                account until you do.
                To enable two-factor authentication, make sure you have a two-factor authenticator app (like Google
                Authenticator or Microsoft Authenticator) installed on your phone.
                When this is done, press the 'enable' button below to start setting up two-factor authentication on your
                account.
            </span>
        </div>
        <div class="w-100 d-flex justify-content-center">
            <button mat-raised-button color="primary" class="cp-button cp-big-button" (click)="onClickEnable()"
                [loading]="loading">
                <span>
                    Enable two-factor authentication
                </span>
            </button>
        </div>
    </ng-container>
    <ng-template #pageTwoRef>
        <ng-container *ngIf="pageIndex === 1; else recoveryCodesRef">
            <div class='explainer'>
                <span>
                    Scan the QR code below with your two-factor authenticator app (like Google Authenticator or
                    Microsoft Authenticator).
                    Alternatively, you can fill the key below in the app instead.
                </span>
            </div>
            <div class='link-container mt-3 justify-content-center'>
                <div class='link'>
                    {{ formattedKey }}
                </div>
                <div class='action-button ml-3' (click)='copyKey()'>
                    <cp-icon name='clipboard' size='small'></cp-icon>
                </div>
            </div>
            <div class='qr-container mt-3 justify-content-center'>
                <div *ngIf="errorLoadingQr; else qrContentRef" class="alert alert-warning">
                    <span>
                        Something went wrong trying to generate a QR code, but you can still use the key above to link
                        an authenticator. Otherwise, please try again later!
                    </span>
                </div>
                <ng-template #qrContentRef>
                    <div class='qr-code'>
                        <div *ngIf='qrLoading' class='qr-placeholder'>
                            <mat-spinner class='m-auto' color='primary'></mat-spinner>
                        </div>
                        <img [src]='qrCode' [class.invisible]='qrLoading' (load)='qrLoading = false;'>
                    </div>
                </ng-template>
            </div>
            <div>
                <div class='label'>
                    <span>
                        After linking your authenticator, please enter the generated code in the input below and click
                        'Verify'.
                    </span>
                </div>
                <mat-form-field class="cp-light-thin-form-field">
                    <input matInput [(ngModel)]='verificationCode' />
                </mat-form-field>
            </div>
            <div>
                <button [loading]="loading" mat-raised-button color="primary" class="cp-button cp-big-button"
                    (click)="submitCode()">
                    <span [translate]="'general.button.verify'">
                        Verify
                    </span>
                </button>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #recoveryCodesRef>
        <div class='explainer'>
            <span>
                Below are your recovery codes. Make sure to save these codes somewhere, as you will need them to access
                your account if you ever lose access to your authenticator app.
                NOTE: you can't view these codes again after closing this dialog, so make sure to save them now!
            </span>
        </div>
        <div class="row my-3">
            <div *ngFor="let code of recoveryCodes" class="col-6">
                {{ code }}
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center">
            <button mat-raised-button color="primary" class="cp-button cp-big-button" (click)="onClickDone()">
                <span>
                    I have saved my recovery codes
                </span>
            </button>
        </div>
    </ng-template>
</mat-dialog-content>