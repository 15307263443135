import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) { }

    // date pipe can take any as value.
    transform(value: any, pattern: string = 'mediumDate'): string | null {
        return this.datePipe.transform(value, pattern, undefined, 'nl');
    }
}
