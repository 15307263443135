export class StringGenerator {
    public static generateRandomString(length: number = 50): string {
        // Valid bitwise usecase.
        // eslint-disable-next-line no-bitwise
        return [...Array(length)].map(_ => (~~(Math.random() * 36)).toString(36)).join('');
    }
}

export function ReplaceWordInString(originalValue: string | undefined, wordToReplace: string, wordToReplaceWith: string): string {
    if (!originalValue) {
        return '';
    }
    const regex = new RegExp(`(?<![<"])\\b${wordToReplace}\\b(?![>"])`, 'gmi');
    return originalValue.replace(regex, wordToReplaceWith);
}
